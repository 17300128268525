// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

/* eslint-disable react/jsx-max-depth */

import { createElement, Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import JswComponent from 'plesk/components/JswComponent';
import Cookie from 'common/cookie';
import LoginMobileApplicationPromotion from './LoginMobileApplicationPromotion';
import Brand from './Brand';

const MOBILE_APPLICATION_PROMOTION_COOKIE_NAME = 'mobile-application-promotion-hidden';

const LoginLayout = ({ logo, mobileApplicationPromotion, extensionsAdditionalBody, children }) => {
    const [
        isMobileApplicationPromotionVisible,
        setMobileApplicationPromotionVisible,
    ] = useState(Boolean(mobileApplicationPromotion && Cookie.get(MOBILE_APPLICATION_PROMOTION_COOKIE_NAME) !== '1'));

    const handleMobileApplicationPromotionHide = () => {
        setMobileApplicationPromotionVisible(false);
        Cookie.setPermanent(MOBILE_APPLICATION_PROMOTION_COOKIE_NAME, '1', '/');
    };

    return (
        <Fragment>
            <div id="login-page">
                {isMobileApplicationPromotionVisible ? (
                    <LoginMobileApplicationPromotion {...mobileApplicationPromotion} onHide={handleMobileApplicationPromotionHide} />
                ) : null}
                <div id="wrapper">
                    <div id="top">
                        <div id="header-wrapper">
                            <div id="header">
                                <h1 className="logo">
                                    <Brand {...logo} />
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div id="content-wrapper">
                        <div id="content">
                            <div id="content-body" className="clearfix">
                                <div id="main">
                                    {children}
                                    <JswComponent>{extensionsAdditionalBody}</JswComponent>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="bottomAnchor" style={{ position: 'fixed', top: 0, zIndex: -1, opacity: 0.001 }}>{Date.now()}</div>
        </Fragment>
    );
};

LoginLayout.propTypes = {
    logo: PropTypes.object.isRequired,
    mobileApplicationPromotion: PropTypes.object,
    extensionsAdditionalBody: PropTypes.string,
    children: PropTypes.any,
};

LoginLayout.defaultProps = {
    mobileApplicationPromotion: undefined,
    extensionsAdditionalBody: undefined,
    children: undefined,
};

export const withLoginLayout = Component => {
    // eslint-disable-next-line react/prop-types
    const Wrapper = ({ logo, mobileApplicationPromotion, extensionsAdditionalBody, ...props }) => (
        <LoginLayout logo={logo} mobileApplicationPromotion={mobileApplicationPromotion} extensionsAdditionalBody={extensionsAdditionalBody}>
            <Component {...props} />
        </LoginLayout>
    );
    Wrapper.displayName = `withLoginLayout(${Component.displayName || Component.name || 'Component'})`;
    Wrapper.WrappedComponent = Component;

    return Wrapper;
};

export default LoginLayout;
