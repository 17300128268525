// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

import { Text, Button, Translate } from '@plesk/ui-library';
import { createElement } from 'react';
import PropTypes from 'prop-types';
import { NotificationPropType } from './lib/PropTypes';

const NotificationToast = ({
    notification,
    onSelectNotification,
}) => {
    const { subject, context } = notification;
    return (
        <div>
            <Text>{context}</Text>
            <br />
            <Text bold>{subject}</Text>
            <br />
            <Button data-type="toaster-notification-learn-more" ghost onClick={() => onSelectNotification({ notification })}>
                <Translate content="notification-center.learnMore" />
            </Button>
        </div>
    );
};

NotificationToast.propTypes = {
    notification: NotificationPropType.isRequired,
    onSelectNotification: PropTypes.func.isRequired,
};

export default NotificationToast;
