// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

/* eslint-disable react/jsx-max-depth */

import { createElement, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Translate } from '@plesk/ui-library';
import { withLoginLayout } from './LoginLayout';

const Login = ({ warnings, values, availableLocales, params }, { locale }) => {
    const formRef = useRef();
    const loginNameRef = useRef();
    const passwordRef = useRef();

    useEffect(() => {
        loginNameRef.current.focus();
    }, []);

    const handleLocaleChange = () => {
        formRef.current.submit();
    };

    const handleSubmit = e => {
        if (loginNameRef.current.value === '' || passwordRef.current.value === '') {
            // eslint-disable-next-line no-alert
            alert(locale.lmsg('enterLoginAndPasswd'));
            e.preventDefault();
        }
    };

    return (
        <form
            ref={formRef}
            id="form-login"
            encType="application/x-www-form-urlencoded"
            method="post"
            onSubmit={handleSubmit}
        >
            <div id="loginSection" className="form-box">
                <div id="loginSection-content-area">
                    {warnings ? (
                        // eslint-disable-next-line react/no-danger
                        <div dangerouslySetInnerHTML={{ __html: warnings }} />
                    ) : null}
                    <div id="loginSection-username-form-row" className="form-row">
                        <div className="field-name"><label htmlFor="loginSection-username"><Translate content="loginLabel" />&nbsp;</label></div>
                        <div className="field-value">
                            <input
                                ref={loginNameRef}
                                type="text"
                                name="login_name"
                                id="loginSection-username"
                                defaultValue={values.loginName}
                                className="input-text"
                            />
                        </div>
                    </div>

                    <div id="loginSection-password-form-row" className="form-row" >
                        <div className="field-name"><label htmlFor="loginSection-password"><Translate content="passwdLabel" />&nbsp;</label></div>
                        <div className="field-value">
                            <input
                                ref={passwordRef}
                                type="password"
                                name="passwd"
                                id="loginSection-password"
                                defaultValue=""
                                className="input-text"
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="field-name"><label htmlFor="fid-locale"><Translate content="localeLabel" />&nbsp;</label></div>
                        <div className="field-value">
                            <select name="locale_id" id="fid-locale_id" defaultValue={values.localeId} onChange={handleLocaleChange}>
                                <Translate content="defaultLocale" component="option" value="default" />
                                {availableLocales.map(({ code, name, dir }) => (
                                    <option key={code} value={code} dir={dir}>{name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            <div className="btns-box">
                <div className="box-area">
                    <div className="form-row">
                        <div className="field-name">
                            <a href="/get_password.php"><Translate content="forgotPasswordLabel" /></a>
                        </div>
                        <div className="field-value">
                            <button id="btn-send" className="btn" type="submit" value="" name="send"><Translate content="loginButtonLabel" /></button>

                            <input type="image" src={require('images/blank.gif')} style={{ border: 0, height: 0, width: 0, position: 'absolute', left: '-9999px' }} />
                        </div>
                    </div>
                </div>
            </div>

            {document.getElementById('forgery_protection_token') ? (
                <input
                    type="hidden"
                    name="forgery_protection_token"
                    value={document.getElementById('forgery_protection_token').content}
                />
            ) : null}

            {Object.keys(params).map(name => (
                <input
                    key={name}
                    name={name}
                    value={params[name]}
                    type="hidden"
                />
            ))}
        </form>
    );
};

Login.propTypes = {
    warnings: PropTypes.string,
    values: PropTypes.shape({
        loginName: PropTypes.string,
        localeId: PropTypes.string,
    }),
    availableLocales: PropTypes.array.isRequired,
    params: PropTypes.object,
};

Login.defaultProps = {
    warnings: '',
    params: {},
    values: {},
};

Login.contextTypes = {
    locale: PropTypes.object,
};

export default withLoginLayout(Login);
