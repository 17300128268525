// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

import { createElement } from 'react';

import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import RequireComponent from './RequireComponent';

const RouteRequire = ({ path, dataPath, component, ...props }) => (
    <Route
        path={path}
        render={() => <RequireComponent dataPath={dataPath} component={component} />}
        {...props}
    />
);

RouteRequire.propTypes = {
    path: PropTypes.string.isRequired,
    dataPath: PropTypes.string,
    component: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.func,
    ]).isRequired,
};

RouteRequire.defaultProps = {
    dataPath: null,
};

export default RouteRequire;
